import { useState } from 'react';
import CalendyForm from './Components/CalendyForm';
import CalendyThankyou from './Components/CalendyThankyou';

const CalendyCustomForm = ({ componentValue }: any) => {
  const [formSubmitted, setFormSubmitted] = useState<boolean>(false);
  const { selectedVal } = componentValue;

  const classesName = [
    '_header_tag',
    '_font_family',
    '_final_class',
    '_font_size',
    '_font_weight',
    '_line_height',
    '_letter_spacing',
    '_text_alignment',
    '_font_style',
    '_text_transform',
    '_top_margin',
    '_top_padding',
    '_left_margin',
    '_left_padding',
    '_right_padding',
    '_right_margin',
    '_bottom_padding',
    '_bottom_margin',
    '_font_color',
  ];

  const getHeadline = (headline: any, headNO: number) => {
    if (headline && headline[`Headline${headNO}`]) {
      let className = '';

      classesName.forEach((classN) => {
        if (headline[`Headline${headNO}${classN}`]) {
          className += ` ${headline[`Headline${headNO}${classN}`].value}`;
        }
      });
      let HeadlineTag = 'div';
      if (headline[`Headline${headNO}_header_tag`]) {
        HeadlineTag = headline[`Headline${headNO}_header_tag`].value;
      }

      return `
        <${HeadlineTag} style="color: ${
        headline['Headline' + headNO + '_font_color'].value
      }" class="${className}">${
        headline[`Headline${headNO}`].value
      }</${HeadlineTag}>
      `;
    }
    return '';
  };

  const getDescription = (Description: any, description: string) => {
    if (Description && Description[`${description}`]) {
      let className = '';

      classesName.forEach((classN) => {
        if (Description[`${description}${classN}`]) {
          className += ` ${Description[`${description}${classN}`].value}`;
        }
      });

      return `
        <div style="color: ${
          Description[description + '_font_color']?.value
        }" class="${className}">${Description[`${description}`].value}</div>
      `;
    }
    return '';
  };

  return (
    <div className='w-full calendy-form'>
      <div className='pl-[15px] pr-[15px] mx-auto relative max-w-[920px]'>
        <div className='flex flex-wrap w-full py-[30px]'>
          {!formSubmitted ? (
            <CalendyForm setFormSubmit={setFormSubmitted} />
          ) : (
            <CalendyThankyou />
          )}
          <div className='w-full lg:w-1/2 px-0 lg:pl-[20px] pb-[0px] items-start justify-center pt-[30px]'>
            <div
              className='w-full text-[16px] font-semibold leading-[20px] mb-[20px]'
              dangerouslySetInnerHTML={{
                __html: getHeadline(selectedVal, 1),
              }}
            ></div>

            <div
              className='w-full mb-[30px]'
              dangerouslySetInnerHTML={{
                __html: getDescription(selectedVal, 'Description1'),
              }}
            ></div>
            <div
              className='w-full mb-[30px]'
              dangerouslySetInnerHTML={{
                __html: getDescription(selectedVal, 'SubDescription'),
              }}
            ></div>
            <div
              className='w-full text-[14px] leading-[18px] mb-[20px]'
              dangerouslySetInnerHTML={{
                __html: getHeadline(selectedVal, 2),
              }}
            ></div>
            <div
              className='w-full'
              dangerouslySetInnerHTML={{
                __html: getDescription(selectedVal, 'Description2'),
              }}
            ></div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CalendyCustomForm;
