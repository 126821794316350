import ShareIcons from '@templates/Home/components/SocialShare';
import { useTypedSelector_v2 } from '@hooks_v2/index';
import { useEffect, useState } from 'react';
import AtoZBrand from './components/AtoZBrand';
import BrandsTabbing from './components/BrandsTabbing';
import ElementAccordionDisplay from './components/ElementAccordionDisplay';
import ElementFullSlider from './components/ElementFullSlider';
import FeaturedProducts from './components/FeaturedProducts';
import CalendyCustomForm from '@templates/CalendyCustomForm';
import ImageVideoSlider from './components/ImageVideoSlider';
import ElementScrollableLogos from './components/ElementScrollableLogos';
import ElementTwoButtons from './components/ElementTwoButtons';
import ElementImageGallery from './components/ElementImageGallery';

const Home2 = ({ html, pageData }) => {

  const storeLogo = useTypedSelector_v2(
    (state) => state.store.mediaBaseUrl + state.store.logoUrl,
  );

  return (
    <div id='allContents'>
      <main>
        {html &&
          html.map((data, index) => {
            let html = <></>;
            if (data.attributes) {
              if (data.type === 1) {
                html = <ShareIcons mediaURL={storeLogo} />;
              } else if (data.type === 2) {
                html = (
                  <FeaturedProducts
                    {...data.data}
                    tabFeaturedPayload={pageData.featuredItems}
                  />
                );
              } else if (data.type === 3) {
                html = <ImageVideoSlider {...data.data} isCentered={data.attributes.node2.isImageCentered}/>;
              } else if (data.type === 4) {
                const componentValue = data.data;
                html = (
                  <>
                    {data.title && (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: data.title,
                        }}
                      />
                    )}
                    <ul className='w-full'>
                      <ElementAccordionDisplay
                        selected_Values={componentValue}
                        acValues={componentValue?.FullAccordion?.value}
                      />
                    </ul>
                  </>
                );
              } else if (data.type === 5) {
                html = <ElementFullSlider {...data.data} />;
              } else if (data.type === 6) {
                html = <AtoZBrand />;
              } else if (data.type === 7) {
                html = <BrandsTabbing {...data.data} />;
              } else if (data.type === 8) {
                html = <CalendyCustomForm {...data.data} />;
              } else if (data.type === 9) {
                html = <ElementImageGallery {...data.data} />;
              } else if (data.type === 10) {
                html = <ElementScrollableLogos {...data.data} />;
              } else if (data.type === 11) {
                html = <ElementTwoButtons {...data.data} />;
              }

              return (
                <div key = {index} {...data.attributes.node1}>
                  <section {...data.attributes.node2}>
                    <div {...data.attributes.node3}>{html}</div>
                  </section>
                </div>
              );
            }
            return (
              <div key = {index}
                dangerouslySetInnerHTML={{
                  __html: data,
                }}
              ></div>
            );
          })}
      </main>
    </div>
  );
};
export default Home2;
