import { paths } from '@constants/paths.constant';
import Link from 'next/link';

const CalendyThankyou = () => {
  return (
      <div className='w-full lg:w-1/2 rounded-lg bg-white shadow-[0_0px_5px_rgb(0,0,0,0.5)] pt-[12px] pb-[12px] mb-[15px]'>
        <div className='flex flex-wrap'>
          <div className='w-full px-[15px] lg:px-[30px] my-[15px]'>
            <div className='w-full text-center'>
              <i className='fa-solid fa-heart-pulse text-secondarytext text-[40px]'></i>
            </div>
            <div className='w-full text-[20px] lg:text-[24px] font-semibold text-center mb-[25px] leading-[14px] sm:leading-[21px] lg:leading-[30px]'>
              Your Request Has Been Received!
            </div>
            <div className='w-full text-[16px] text-center mb-[18px]'>
              A member of our customer success team will contact you shortly.
            </div>
            <div className='w-full text-center mb-[40px]'>
              <div className='btn btn-md btn-primary w-auto font-[700]'>
                <Link href={paths.BOOKCONSULT}>BOOK CONSULTATION</Link>
              </div>
            </div>
            <div className='w-full text-[14px] text-center px-[20px] font-[700] pk-custom-btn'>
              Or Shop Brands We Know You'll Love.
            </div>
            <div className='grid grid-cols-2 gap-4 my-[15px]'>
              <div className='bg-black max-h-auto'>
                <a href='/patagonia.html' title='patagonia'>
                  <img
                    src='https://storagemedia.corporategear.com/storagemedia/1/brand/color_71_7.png'
                    alt='patagonia'
                    className='max-w-[100%]'
                  />
                </a>
              </div>
              <div className='bg-black'>
                <a href='/yeti.html' title='yeti'>
                  <img
                    src='https://storagemedia.corporategear.com/storagemedia/1/brand/color_72_7.png'
                    alt='yeti'
                    className='max-w-[100%]'
                  />
                </a>
              </div>
              <div className='bg-black max-h-auto'>
                <a href='/nike.html' title='Nike'>
                  <img
                    src='https://storagemedia.corporategear.com/storagemedia/1/brand/color_73_7.png'
                    alt='Nike'
                    className='max-w-[100%]'
                  />
                </a>
              </div>
              <div className='bg-black'>
                <a href='peter-millar.html' title='Peter Millar'>
                  <img
                    src='https://storagemedia.corporategear.com/storagemedia/1/brand/color_76_7.png'
                    alt='Peter Millar'
                    className='max-w-[100%]'
                  />
                </a>
              </div>
            </div>
            <div className='w-full text-center mb-[30px]'>
              <div className='btn btn-md btn-primary w-auto font-[700] pk-custom-btn'>
                <Link href={paths.SHOP_BY_BRAND}>SHOP BY BRAND</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
};

export default CalendyThankyou;
