import { useState } from 'react';
import Slider from 'react-slick';

export interface BannerArray {
  showThumb: string;
  showArrow: string;
  infiniteLoop: string;
  autoPlay: string;
  stopOnHover: string;
  showIndicators: string;
  showStatus: string;
  arrowType: string;
  images: Image[];
}

export interface Image {
  btn_hpos: string;
  srno: number;
  image_url: string;
  image_link: string;
  image_alt: string;
  image_or_video: string;
  video_type: string;
  video_url: string;
  headline1_display: boolean;
  headline: string;
  font_size: string;
  text_transform: string;
  font_family: string;
  font_color: string;
  line_height: string;
  letter_spacing: string;
  font_weight: string;
  font_style: string;
  text_decoration: string;
  text_align: string;
  image_video_bg_color: string;
  left_padding: string;
  top_padding: string;
  right_padding: string;
  bottom_padding: string;
  left_margin: string;
  top_margin: string;
  right_margin: string;
  bottom_margin: string;
  headline_tag: string;
  headline1_box_shadow: string;
  headline1_class: string;
  headline1: string;
  font_size1: string;
  text_transform1: string;
  font_family1: string;
  font_color1: string;
  line_height1: string;
  letter_spacing1: string;
  font_weight1: string;
  font_style1: string;
  text_decoration1: string;
  text_align1: string;
  left_padding1: string;
  top_padding1: string;
  right_padding1: string;
  bottom_padding1: string;
  left_margin1: string;
  top_margin1: string;
  right_margin1: string;
  bottom_margin1: string;
  headline2_box_shadow: string;
  headline2_class: string;
  description_class: string;
  button_class: string;
  button_box_shadow: string;
  button_class1: string;
  button_box_shadow1: string;
  button_display: string;
  button_text: string;
  button_text_transform: string;
  button_style: string;
  button_size: string;
  button_link: string;
  button_link_window: string;
  button_link_follow: string;
  btn_font_family: string;
  btn_font_size: string;
  btn_font_weight: string;
  button_text_alignment: string;
  button_letter_spacing: string;
  btn_font_line_height: string;
  btn_top_padding: string;
  btn_right_padding: string;
  btn_bottom_padding: string;
  btn_left_padding: string;
  btn_top_margin: string;
  btn_right_margin: string;
  btn_bottom_margin: string;
  btn_left_margin: string;
  text_hpos: string;
  text_vpos: string;
  text_bg_color: string;
  bg_opacity: number;
  bg_hex_color: string;
  headline_width: string;
  image_height_width: ImageHeightWidth;
  visibility?: boolean;
  icon_image_url: string;
  description: string;
  button_text1: string;
  headline_font_size: string;
  headline2_display: string;
  description_display: string;
  font_color2: string;
  button_display1: string;
  button_alt: string;
  button_link1: string;
  button_alt1: string;
  button_link_follow1: string;
  button_link_window1: string;
  headline_tag1: string;
  button_text_alignment1: string;
  headline_aos_effect: string;
  headline1_aos_effect: string;
  button_aos_effect: string;
  button_font_color: string;
  button_font_color1: string;
  button1_aos_effect: string;
}

export interface ImageHeightWidth {
  height: number;
  width: number;
}

const PrevBtn = (props: any) => {
  const { onClick, arrowType } = props;
  return (
    <div
      className='absolute top-1/2 -translate-y-1/2 left-4 z-10 flex items-center'
      style={{ zIndex: '39' }}
      onClick={onClick}
    >
      {arrowType === 'Arrow1' && (
        <button
          name='Previous'
          // onClick={clickHandler}
          className='bg-light-gray bg-opacity-90 flex justify-center items-center w-10 h-10 rounded-md shadow-md focus:outline-none'
        >
          <svg
            viewBox='0 0 20 20'
            fill='currentColor'
            className='chevron-left w-10 h-10'
          >
            <path
              fillRule='evenodd'
              d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
      {arrowType === 'Arrow2' && (
        <button
          name='Previous'
          // onClick={clickHandler}
          className='bg-white -ml-2 lg:-ml-4 flex justify-center items-center w-10 h-10 rounded-full shadow focus:outline-none'
          style={{ zIndex: '39' }}
        >
          <svg
            viewBox='0 0 20 20'
            fill='currentColor'
            className='chevron-left w-6 h-6'
          >
            <path
              fillRule='evenodd'
              d='M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
};

const NextBtn = (props: any) => {
  const { className, style, onClick, arrowType } = props;
  return (
    <div
      className='absolute top-1/2 -translate-y-1/2 right-4 z-10 flex items-center'
      style={{ zIndex: '39' }}
      onClick={onClick}
    >
      {arrowType === 'Arrow1' && (
        <button
          name='Next'
          // onClick={clickHandler}
          className='bg-light-gray bg-opacity-90 flex justify-center items-center w-10 h-10 rounded-md shadow-md focus:outline-none'
        >
          <svg
            viewBox='0 0 20 20'
            fill='currentColor'
            className='chevron-right w-10 h-10'
          >
            <path
              fillRule='evenodd'
              d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
      {arrowType === 'Arrow2' && (
        <button
          name='Next'
          // onClick={clickHandler}
          className='bg-white -mr-2 lg:-mr-4 flex justify-center items-center w-10 h-10 rounded-full shadow focus:outline-none'
          style={{ zIndex: '39' }}
        >
          <svg
            viewBox='0 0 20 20'
            fill='currentColor'
            className='chevron-right w-6 h-6'
          >
            <path
              fillRule='evenodd'
              d='M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z'
              clipRule='evenodd'
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
};

const ImageVideoSlider = ({
  bannerArr,
  isCentered,
}: {
  bannerArr: BannerArray;
  isCentered: boolean;
}) => {
  let tmpArr = bannerArr?.images?.filter(
    (image) => image.visibility === undefined || image.visibility,
  );
  if (!tmpArr?.length) {
    return <></>;
  }
  const arrowType = bannerArr?.arrowType ? bannerArr?.arrowType : 'Arrow1';
  const [currentSlide, setCurrentSlideNumber] = useState(0);
  const settings = {
    dots: bannerArr?.showIndicators === 'On' && tmpArr?.length > 0,
    arrows: bannerArr?.showArrow === 'On' && tmpArr?.length > 0,
    infinite: bannerArr?.infiniteLoop === 'On' && tmpArr?.length > 0,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: bannerArr?.autoPlay === 'On' && tmpArr?.length > 0,
    autoplaySpeed: 2000,
    pauseOnHover: bannerArr?.stopOnHover === 'On' && tmpArr?.length > 0,
    adaptiveHeight: false,
    nextArrow: <NextBtn arrowType='Arrow1' />,
    prevArrow: <PrevBtn arrowType='Arrow1' />,
    dotsClass: 'control-dots slick-dots',
    beforeChange: (currentSlide: number, nextSlide: number) =>
      setCurrentSlideNumber(nextSlide),
  };

  return (
    // <div
    //   className='carousel'
    //   onMouseEnter={() => sliderSettings.stopOnHover && setStopOnHover(true)}
    //   onMouseLeave={() => {
    //     setStopOnHover(false);
    //   }}
    // >

    //   {sliderSettings.showArrow && (
    //     <PrevBtn arrowType={'Arrow1'} clickHandler={prev} />
    //   )}
    //   <ul className='carousel-slider' ref={sliderRef}>
    <div className='relative'>
      {bannerArr?.showStatus === 'On' && (
        <p className='carousel-status'>
          {currentSlide + 1} of {tmpArr.length}
        </p>
      )}
      <Slider {...settings}>
        {tmpArr.map((image, backgroundIndex) => {
          let headlineStartTag = '';
          let headline1StartTag = '';
          let headlineEndTag = '';
          let headline1EndTag = '';
          if (image.headline_tag) {
            headlineStartTag = '<' + image.headline_tag + '>';
            headlineEndTag = '</' + image.headline_tag + '>';
          }
          if (image.headline_tag1) {
            headline1StartTag = '<' + image.headline_tag1 + '>';
            headline1EndTag = '</' + image.headline_tag1 + '>';
          }
          return (
            <div key={backgroundIndex}>
              <div
                // style={
                //   image.image_or_video == 'Image'
                //     ? getSlideStyle(
                //         image.image_height_width,
                //         image.image_video_bg_color,
                //       )
                //     : {}
                // }
                key={backgroundIndex}
                className={`relative presentation-mode ${
                  image.image_or_video !== 'Image'
                    ? 'cgslide-' + (backgroundIndex + 1)
                    : ''
                }`}
              >
                <div
                // className={
                //   image.image_or_video == 'Image' && !isLoaded
                //     ? 'hidden'
                //     : 'overflow-hidden'
                // }
                //   style={getSlideStyle(image.image_height_width)}
                >
                  {image.image_or_video == 'Image' ? (
                    image.image_url &&
                    (image.image_link ? (
                      <a
                        className={`block${isCentered ? ' text-center' : ''}`}
                        href={`${image.image_link}`}
                        title={image.image_alt}
                      >
                        <img
                          src={`${image.image_url}`}
                          alt={`${image.image_alt}`}
                          className={isCentered ? 'inline-block' : ''}
                        />
                      </a>
                    ) : (
                      <img
                        src={`${image.image_url}`}
                        alt={`${image.image_alt}`}
                      />
                    ))
                  ) : (
                    <>
                      {image.video_type == 'Youtube' ? (
                        <iframe
                          name='Youtube'
                          className='w-full aspect-video'
                          src={`https://www.youtube.com/embed/${image.video_url}?rel=0`}
                          allow='autoplay; encrypted-media'
                        ></iframe>
                      ) : (
                        <iframe
                          name='Vimeo'
                          className='p-0 w-full aspect-[7/3]'
                          src={`https://player.vimeo.com/video/${image.video_url}?autoplay=1&loop=1&background=1&muted=1`}
                          allow='autoplay'
                        ></iframe>
                      )}
                    </>
                  )}
                  {(image.icon_image_url ||
                    image.headline ||
                    image.headline1 ||
                    image.description ||
                    image.button_text ||
                    image.button_text1) && (
                    <div
                      className={`flex ${
                        image.text_hpos ? image.text_hpos : ''
                      } ${image.text_vpos ? image.text_vpos : ''} w-full ${
                        image?.image_url || image?.video_url ? 'absolute ' : ''
                      }${
                        image.headline_font_size
                      } inset-0 p-1 lg:p-4 text-white`}
                    >
                      <div
                        className={`${
                          image.headline_width ? image.headline_width : ''
                        } w-full`}
                        style={{
                          background:
                            image.headline1_display || image.headline2_display
                              ? `rgb(${image.text_bg_color}, ${image.bg_opacity})`
                              : 'none',
                          padding: '20px',
                        }}
                      >
                        {image.icon_image_url && (
                          <div className='text-center'>
                            <img
                              style={{
                                width: 'auto',
                              }}
                              src={image.icon_image_url}
                            />
                          </div>
                        )}
                        {image.headline1_display && (
                          <div
                            className={image.headline1_class ?? ''}
                            data-aos={image?.headline_aos_effect ?? ''}
                            style={{
                              color: image.font_color ?? '',
                              textShadow: image.headline1_box_shadow ?? '',
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                headlineStartTag +
                                image.headline +
                                headlineEndTag,
                            }}
                          ></div>
                        )}
                        {image.headline2_display && (
                          <div
                            className={image.headline2_class ?? ''}
                            data-aos={image?.headline1_aos_effect ?? ''}
                            style={{
                              color: image.font_color1 ?? '',
                              textShadow: image.headline2_box_shadow ?? '',
                            }}
                            dangerouslySetInnerHTML={{
                              __html:
                                headline1StartTag +
                                image.headline1 +
                                headline1EndTag,
                            }}
                          ></div>
                        )}
                        {image.description_display && (
                          <div
                            className={image.description_class ?? ''}
                            style={{ color: image.font_color2 ?? '' }}
                            dangerouslySetInnerHTML={{
                              __html: image.description,
                            }}
                          ></div>
                        )}
                        {image.button_display1 === undefined ? (
                          image.button_display == 'Yes' && (
                            <>
                              <div
                                className={`pt-0 lg:pt-5 ${image?.button_text_alignment}`}
                                title={image.button_text}
                              >
                                <a
                                  href={image.button_link}
                                  data-aos={image?.button_aos_effect ?? ''}
                                  title={
                                    tmpArr[0].button_alt ??
                                    tmpArr[0].button_text
                                  }
                                  target={
                                    image.button_link_window == '_blank'
                                      ? '_blank'
                                      : '_self'
                                  }
                                  className={`${image.button_class}`}
                                  style={{
                                    boxShadow: image?.button_box_shadow,
                                  }}
                                  rel='noreferrer'
                                >
                                  <span
                                    style={{ color: image?.button_font_color }}
                                  >
                                    {image.button_text}
                                  </span>
                                </a>
                              </div>
                            </>
                          )
                        ) : (
                          <>
                            {image.button_display1 === 'Yes' &&
                            image.button_display === 'Yes' ? (
                              <>
                                <div
                                  className={`pt-3 lg:pt-5 flex ${image?.btn_hpos}`}
                                >
                                  <a
                                    href={image.button_link}
                                    data-aos={image?.button_aos_effect ?? ''}
                                    title={
                                      tmpArr[0].button_alt ??
                                      tmpArr[0].button_text
                                    }
                                    target={
                                      image.button_link_window == '_blank'
                                        ? '_blank'
                                        : '_self'
                                    }
                                    className={`${image.button_class}`}
                                    style={{
                                      boxShadow: image?.button_box_shadow,
                                    }}
                                    rel={`${
                                      image?.button_link_follow == 'nofollow'
                                        ? 'nofollow'
                                        : ''
                                    }`}
                                  >
                                    <span
                                      style={{
                                        color: image?.button_font_color,
                                      }}
                                    >
                                      {image.button_text}
                                    </span>
                                  </a>
                                  <a
                                    href={image.button_link1}
                                    data-aos={image?.button1_aos_effect ?? ''}
                                    title={
                                      tmpArr[0].button_alt1 ??
                                      tmpArr[0].button_text1
                                    }
                                    target={
                                      image.button_link_window1 == '_blank'
                                        ? '_blank'
                                        : '_self'
                                    }
                                    className={`${image.button_class1} ${image?.button_text_alignment1}`}
                                    style={{
                                      boxShadow: image?.button_box_shadow1,
                                    }}
                                    rel={`${
                                      image?.button_link_follow1 == 'nofollow'
                                        ? 'nofollow'
                                        : ''
                                    }`}
                                  >
                                    <span
                                      style={{
                                        color: image?.button_font_color1,
                                      }}
                                    >
                                      {image.button_text1}
                                    </span>
                                  </a>
                                </div>
                              </>
                            ) : (
                              <>
                                {image.button_display == 'Yes' && (
                                  <>
                                    <div
                                      className={`pt-0 lg:pt-5 ${image?.button_text_alignment}`}
                                      title={image.button_text}
                                    >
                                      <a
                                        href={image.button_link}
                                        data-aos={
                                          image?.button_aos_effect ?? ''
                                        }
                                        title={
                                          tmpArr[0].button_alt ??
                                          tmpArr[0].button_text
                                        }
                                        target={
                                          image.button_link_window == '_blank'
                                            ? '_blank'
                                            : '_self'
                                        }
                                        className={`${image.button_class}`}
                                        style={{
                                          boxShadow: image?.button_box_shadow,
                                        }}
                                        rel={`${
                                          image?.button_link_follow ==
                                          'nofollow'
                                            ? 'nofollow'
                                            : ''
                                        }`}
                                      >
                                        <span
                                          style={{
                                            color: image?.button_font_color,
                                          }}
                                        >
                                          {image.button_text}
                                        </span>
                                      </a>
                                    </div>
                                  </>
                                )}
                                {image.button_display1 == 'Yes' && (
                                  <>
                                    <div
                                      className={`pt-0 lg:pt-5 ${image?.button_text_alignment1}`}
                                      title={image.button_text1}
                                    >
                                      <a
                                        href={image.button_link1}
                                        data-aos={
                                          image?.button1_aos_effect ?? ''
                                        }
                                        title={
                                          tmpArr[0].button_alt1 ??
                                          tmpArr[0].button_text1
                                        }
                                        target={
                                          image.button_link_window1 == '_blank'
                                            ? '_blank'
                                            : '_self'
                                        }
                                        className={`${image.button_class1}`}
                                        style={{
                                          boxShadow: image?.button_box_shadow1,
                                        }}
                                        rel={`${
                                          image?.button_link_follow1 ==
                                          'nofollow'
                                            ? 'nofollow'
                                            : ''
                                        }`}
                                      >
                                        <span
                                          style={{
                                            color: image?.button_font_color1,
                                          }}
                                        >
                                          {image.button_text1}
                                        </span>
                                      </a>
                                    </div>
                                  </>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          );
        })}
      </Slider>
    </div>
  );
};

export default ImageVideoSlider;
